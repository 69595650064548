import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { ToastRenderer } from "./components/molecules/Toast/Toast";
import PageLayout from "./components/organisms/Layout/Layout";
import { AppProvider } from "./context/AppProvider";
import { AuthProvider } from "./hooks/useAuth";
import {
  AddItemRequest,
  CurrentTrip,
  EditItemRequest,
  GroupDetails,
  HelpInstitutions,
  LocationProfile,
  Login,
  PageNotFound,
  PersonProfile,
  Search,
  Trips,
} from "./pages";
import SingleTrip from "./pages/SingleTrip/SingleTrip";
import { ProtectedRoute } from "./utils/ProtectedRoute";
import { paths } from "./utils/paths";

interface AppProps {
  tripId?: string;
  id?: string;
  itemRequestId?: string;
}

const queryClient = new QueryClient();

export default function App({ tripId, id, itemRequestId }: AppProps) {
  return (
    <AuthProvider>
      <AppProvider>
        <QueryClientProvider client={queryClient}>
          <ToastRenderer />
          <Router>
            <PageLayout>
              <Routes>
                <Route path={paths.main} element={<Login />} />
                <Route
                  path={paths.search}
                  element={
                    <ProtectedRoute>
                      <Search />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={paths.helpInstitutions}
                  element={
                    <ProtectedRoute>
                      <HelpInstitutions />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={paths.personProfile(id)}
                  element={
                    <ProtectedRoute>
                      <PersonProfile />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={paths.addItemRequest(id)}
                  element={
                    <ProtectedRoute>
                      <AddItemRequest />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={paths.editItemRequest(id, itemRequestId)}
                  element={
                    <ProtectedRoute>
                      <EditItemRequest />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={paths.locationProfile(id)}
                  element={
                    <ProtectedRoute>
                      <LocationProfile />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path={paths.currentTrip}
                  element={
                    <ProtectedRoute>
                      <CurrentTrip />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path={paths.groupView(tripId, id)}
                  element={
                    <ProtectedRoute>
                      <GroupDetails />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path={paths.singleTrip(id)}
                  element={
                    <ProtectedRoute>
                      <SingleTrip />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path={paths.trips}
                  element={
                    <ProtectedRoute>
                      <Trips />
                    </ProtectedRoute>
                  }
                />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </PageLayout>
          </Router>
        </QueryClientProvider>
      </AppProvider>
    </AuthProvider>
  );
}
