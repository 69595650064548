import { FC } from "react";
import Skeleton from "react-loading-skeleton";

import Card from "../Card/Card";

interface CardSkeletonProps {
  titleLength?: number; // 1 for full width, smaller number, ex. 0.5 for part width
  content: JSX.Element;
  isContentFullWidth: boolean;
}

const CardSkeleton: FC<CardSkeletonProps> = ({ content, titleLength = 1, isContentFullWidth }) => {
  return (
    <div className="card-skeleton">
      <Card
        header={<Skeleton count={titleLength} enableAnimation={true} duration={1} />}
        content={content}
        isContentFullWidth={isContentFullWidth}
        icon={
          <Skeleton
            circle={true}
            width={24}
            height={24}
            enableAnimation={true}
            duration={1}
            className="card__icon"
          />
        }
      />
    </div>
  );
};

export default CardSkeleton;
