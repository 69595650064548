import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { AppContext } from "../../../context/AppContext";
import { useAuth } from "../../../hooks/useAuth";
import {
  isCurrentTripPage,
  isGroupPage,
  isItemRequestPage,
  isLocationProfile,
  isPage,
  isPersonProfile,
  isTripsPage,
} from "../../../utils/location";
import { paths } from "../../../utils/paths";
import Button, { ButtonTypeEnum } from "../../atoms/Button/Button";
import {
  CloseMenuIcon,
  ExternalLinkIcon,
  HamburgerIcon,
  LogoTextOnly,
  SmallSmiley,
} from "../../icons/Icons";
import BackButton from "../BackButton/BackButton";

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { menuItems, fetchMenuItems } = useContext(AppContext);

  const { logout } = useAuth();

  const classNames = isOpen ? "navigation--is-open" : "";
  const classNamesHelpPage = `navigation--help-institutions ${isOpen ? "navigation--is-open" : ""}`;

  const isHelpPage = isPage(paths.helpInstitutions);
  const isMainPage = isPage(paths.main);
  const isNotFoundPage = isPage(paths.pageNotFound);
  const isGroupDetailsPage = isGroupPage();

  const showBackButton =
    isPersonProfile() ||
    isNotFoundPage ||
    isItemRequestPage() ||
    isLocationProfile() ||
    isGroupDetailsPage ||
    isCurrentTripPage() ||
    isTripsPage();
  const showLogo = !showBackButton;

  const handleClick = () => {
    setIsOpen(!isOpen);
    document.body.style.overflow = isOpen ? "" : "hidden";
  };

  const handleLogout = () => {
    logout();
    handleClick();
  };

  useEffect(() => {
    fetchMenuItems();
  }, []);

  return (
    <div
      className={`${showLogo && !isOpen ? "navigation__logo-container" : "navigation"} ${
        isHelpPage || isNotFoundPage ? classNamesHelpPage : classNames
      } ${isMainPage && "navigation__main-page"}`}
    >
      {!showBackButton && !isOpen && <LogoTextOnly className="navigation__logo" title="Logo" />}
      {!isMainPage && (
        <div className={showBackButton ? "navigation__buttons" : "navigation__buttons--hamburger"}>
          {showBackButton && <BackButton />}
          <Button onClick={handleClick} variant={ButtonTypeEnum.Icon}>
            {isOpen ? <CloseMenuIcon /> : <HamburgerIcon />}
          </Button>
        </div>
      )}

      {isOpen && (
        <>
          <ul className="navigation__menu">
            {menuItems.entities
              .filter((menuItem) => menuItem.isActive)
              .sort((a, b) => a.priorityOrder - b.priorityOrder)
              .map((menuItem) => (
                <li key={menuItem.id} className="navigation__menu-item">
                  {menuItem.itemType === "external" ? (
                    <a
                      href={menuItem.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={handleClick}
                      className="navigation__menu-link"
                    >
                      <span className="navigation__menu-link-name">{menuItem.name}</span>
                      <ExternalLinkIcon />
                    </a>
                  ) : (
                    <Link to={menuItem.url} onClick={handleClick} className="navigation__menu-link">
                      {menuItem.name}
                    </Link>
                  )}
                </li>
              ))}

            <li className=" navigation__menu-item--logout">
              <button className="navigation__logout-button" onClick={handleLogout}>
                Wyloguj
              </button>
            </li>

            <li className="navigation__menu-item">
              <a
                href="https://zupanaplantach.pl/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleClick}
                className="navigation__menu-link"
              >
                <span className="navigation__menu-link-name">zupanaplantach.pl</span>
                <ExternalLinkIcon />
              </a>
            </li>
          </ul>
          <div className="navigation__smiley-container">
            <SmallSmiley className="navigation__smiley" />
          </div>
        </>
      )}
    </div>
  );
};

export default Navigation;
