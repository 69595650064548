import { FC, useContext, useEffect } from "react";

import HelpInstitutionsList from "../../components/organisms/HelpInstitutionsList/HelpInstitutionsList";
import { AppContext, StatusEnum } from "../../context/AppContext";

const HelpInstitutions: FC = () => {
  const { institutions, fetchInstitutions } = useContext(AppContext);
  const isLoading = institutions.responseStatus === StatusEnum.Pending;

  useEffect(() => {
    fetchInstitutions();
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="">Loading...</div>
      ) : (
        <>
          <h1 className="help-institutions__header">Punkty pomocy</h1>
          <HelpInstitutionsList institutions={institutions.entities} />
          <p className="help-institutions__disclaimer">
            * Warunki uzyskania skierowania z MOPS - dana osoba musi być zarejestrowana w systemie
            opieki społecznej. Po skierowanie należy udać się do MOPS - Dział Pomocy Bezdomnym, os.
            Teatralne 24 w Nowej Hucie.
          </p>
        </>
      )}
    </div>
  );
};

export default HelpInstitutions;
