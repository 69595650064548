export const ExclaimationIcon = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.375 18C3.375 9.92284 9.92284 3.375 18 3.375C26.0772 3.375 32.625 9.92284 32.625 18C32.625 26.0772 26.0772 32.625 18 32.625C9.92284 32.625 3.375 26.0772 3.375 18ZM18 12.375C18.6213 12.375 19.125 12.8787 19.125 13.5V19.125C19.125 19.7463 18.6213 20.25 18 20.25C17.3787 20.25 16.875 19.7463 16.875 19.125V13.5C16.875 12.8787 17.3787 12.375 18 12.375ZM18 24.75C18.6213 24.75 19.125 24.2463 19.125 23.625C19.125 23.0037 18.6213 22.5 18 22.5C17.3787 22.5 16.875 23.0037 16.875 23.625C16.875 24.2463 17.3787 24.75 18 24.75Z"
      fill="#C8473D"
    />
  </svg>
);
